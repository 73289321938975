import React from "react"
import { navigate } from "@reach/router"

const Services = () => {
    React.useEffect(() => {
        navigate('https://giftmallcorp.jp/service/');
    }, []);
    return null;
};

export default Services

